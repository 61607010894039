<template>
  <div class="video">
    <div class="block-title">
      <a-button type="primary" style="margin-right: 20px" @click="showUp">
        <a-icon type="plus-circle" />上传视频
      </a-button>

      <a-input-search placeholder="输入视频标题" enter-button @search="onSearch" v-model="title_name" :loading="dataLoading" />
    </div>
    <div class="list-block">
      <a-table
        :rowKey="
          (item, index) => {
            return index;
          }
        "
        :loading="dataLoading"
        :columns="columns"
        :data-source="list"
        :bordered="true"
        :pagination="{
          hideOnSinglePage: true,
          current: page.current,
          total: page.total,
        }"
        @change="listChange"
      >
        <template slot="header_img" slot-scope="ind, item">
          <div class="img-box">
            <img :src="$imglink + item.header_img" alt />
          </div>
        </template>
        <template slot="link" slot-scope="ind, item">
          <div class="link-box">{{ $imglink + item.resource_id }}</div>
        </template>
        <template slot="is_show" slot-scope="ind, item">
          <a-button
            size="small"
            :type="item.is_show == 1 ? 'primary' : 'default'"
            class="qiy"
            :class="{ act: item.is_show == 1 }"
            @click="toggleShow(item.v_id, item.is_show, ind)"
          >{{ item.is_show == 1 ? "已上架" : "已下架" }}</a-button>
        </template>
        <template slot="operation" slot-scope="index, item">
          <div class="control-box">
            <a-button :loading="editIng" size="small" @click="showEdit(item)" type="primary">编辑</a-button>
            <a-popconfirm title="确认删除该视频" ok-text="是" cancel-text="否" @confirm="deleteItem(item)">
              <a-button :loading="deleteIng" size="small" type="danger">删除</a-button>
            </a-popconfirm>
          </div>
        </template>
      </a-table>
    </div>
    <a-drawer :title="(form.v_id ? '修改' : '上传') + '视频'" class="drawer-box" :width="720" :visible="visible" :body-style="{ paddingBottom: '80px' }" @close="drawerClose">
      <div class="table-box">
        <div class="table-item">
          <div class="title">视频标题</div>
          <div class="input-box">
            <a-input placeholder="请输入" v-model="form.title_name" />
          </div>
        </div>
        <div class="table-item">
          <div class="title">上传视频</div>
          <div>
            <a-upload class="upload-btn" :multiple="true" :file-list="fileList" :action="$domain + '/api/resource/uploadVideo'" @change="handleChange">
              <a-button v-if="fileList.length <= 0" type="primary" :loading="uploading">
                <a-icon type="upload" />上传视频
              </a-button>
            </a-upload>
          </div>
        </div>
        <a-button type="primary" :loading="subing" @click="submitImg">提交</a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
export default {
  data() {
    let columns = [
      {
        title: "序号",
        customRender: (text, record, index) =>
          `${(this.page.current - 1) * 10 + index + 1} `,
        width: "61px",
      },
      {
        title: "视频封面",
        key: "header_img",
        scopedSlots: { customRender: "header_img" },
        width: "200px",
      },
      {
        title: "标题",
        key: "title_name",
        dataIndex: "title_name",
      },

      {
        title: "视频地址",
        key: "link",
        scopedSlots: { customRender: "link" },
      },
      {
        title: "视频大小",
        key: "size",
        customRender: (text, record, index) => `${record.size}M`,
      },
      {
        title: "上传日期",
        dataIndex: "create_at_str",
        key: "create_at_str",
      },
      {
        title: "状态",
        dataIndex: "is_show",
        scopedSlots: { customRender: "is_show" },
        width: "65px",
      },
      {
        title: "操作",
        dataIndex: "operation",
        scopedSlots: { customRender: "operation" },
        width: "140px",
      },
    ];
    return {
      columns,
      dataLoading: false,
      list: [],
      page: {
        current: 1,
        total: 0,
      },
      deleteIng: false,
      editIng: false,
      title_name: "",
      form: {
        size: "",
        name: "",
        resource_id: "",
        is_show: 1,
        header_img: "",
        title_name: " ",
      },
      visible: false,
      uploading: false,
      subing: false,
      fileList: [],
    };
  },
  computed: {},
  created() {
    this.getVideoList();
  },
  mounted() {
    this.$message.config({
      maxCount: 1
    })
  },
  methods: {
    onSearch() {
      this.getVideoList(true);
    },
    getVideoList(refresh) {
      this.dataLoading = true
      if (refresh) {
        this.page.current = 1;
      }
      this.$post("VideoList", {
        title_name: this.title_name,
        page: this.page.current,
      }).then((res) => {
        this.dataLoading = false
        let { code, msg, data } = res;
        if (code == 0) {
          this.list = data.list;
          this.total = data.total;
        }
      }).catch(err => {
        this.dataLoading = false
      })
    },
    deleteItem(item) {
      this.deleteIng = true;
      this.$post("VideoDelete", {
        v_id: item.v_id,
      })
        .then((res) => {
          let { code, msg, data } = res;
          this.deleteIng = false;
          if (code == 0) {
            this.$message.success("删除成功", 1.5);
            this.getVideoList();
          } else {
            this.$message.error(msg, 1.5);
          }
        })
        .catch((err) => {
          this.deleteIng = false;
        });
    },
    handleChange(e) {
      console.log(e.fileList);

      if (e.file.status !== "uploading") {
        this.uploading = false;
      } else {
        this.uploading = true;
      }
      if (e.file.status == "done") {
        let { msg, data, code } = e.file.response;
        this.form.resource_id = data.resource_id;
        this.form.header_img = data.resource_id + "&res_type=video_s";
        this.form.name = data.name;
        this.form.size = data.size;
      }
      this.fileList = e.fileList;
    },
    initForm() {
      this.form = {
        size: "",
        name: "",
        resource_id: "",
        is_show: 1,
        header_img: "",
        title_name: "",
      };
    },
    submitImg() {
      let t = this;

      let url = t.form.v_id ? "VideoEdit" : "VideoAdd";
      if (t.form.title_name == '') {
        return t.$message.warn('请填写视频标题', 1.5)
      }
      if (t.form.resource_id == '') {
        return t.$message.warn('请上传视频', 1.5)
      }
      t.subing = true;
      t.$post(url, this.form)
        .then((res) => {
          let { code, data, msg } = res;
          t.subing = false;
          t.visible = false;
          if (code == 0) {
            t.$message.success(msg, 1.5);
            t.initForm();
            this.getVideoList()
          } else {
            t.$message.success(msg, 1.5);
          }
        })
        .catch((err) => {
          t.subing = false;
        });
    },

    drawerClose() {
      this.visible = false;
      this.initForm()
      this.fileList = []
    },
    showUp() {
      this.visible = true;
    },
    showEdit(item) {
      this.visible = true;
      this.fileList = [
        {
          uid: -1,
          type: "video/mp4",
          status: "done",
          name: item.name,
        },
      ];
      this.form = {
        size: item.size,
        name: item.name,
        resource_id: item.resource_id,
        is_show: item.is_show,
        header_img: item.header_img,
        title_name: item.title_name,
        v_id: item.v_id,
      };
    },
    toggleShow(id, show, ind) {
      console.log(id, show, ind)
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].v_id == id) {
          this.list[i].is_show = show == 1 ? -1 : 1
          break
        }
      }
      this.$post('VideoFabu', {
        v_id: id
      }).then(res => {
        let { code, msg, data } = res
        if (code == 0) {
          this.$message.success(msg, 1.5)
        } else {
          this.$message.error(msg, 1.5)
          for (let i = 0; i < this.list.length; i++) {
            if (this.list[i].v_id == id) {
              this.list[i].is_show = show
              break
            }
          }
        }
      })
    },
    listChange(e) {
      this.page.current = e.current
      this.getVideoList()
    }
  },
};
</script>

<style lang="less">
.block-title {
  display: flex;
  margin-bottom: 20px;
}
.table-item {
  display: flex;
  margin-bottom: 20px;
  .title {
    width: 70px;
    line-height: 32px;
  }
  .input-box {
    width: calc(~"100% - 70px");
  }
}
.img-box {
  max-width: 200px;
  img {
    width: 100%;
    height: 94px;
    object-fit: cover;
  }
}
.ant-upload {
  width: 200px;
}
.upload-btn {
  .ant-upload-list-item {
    margin-top: 0;
    line-height: 32px;
    height: 32px;
    width: 180px;

    .anticon {
      margin-top: 4px;
    }
  }
}
</style>
